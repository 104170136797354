import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import JobsPageTemplate from '../components/JobsPageTemplate'
import Layout from '../components/Layout'

const JobsPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
      </Helmet>
      <JobsPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle} />
    </Layout>
  )
}

JobsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default JobsPage

export const jobsPageQuery = graphql`
  query JobsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        meta_title
        meta_description
      }
    }
  }
`
