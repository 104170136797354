// JobPageTemplate/index.js
//
// React Component for the main content of the Job Listing page
// URLs: /jobs/

import './index.css'
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import AOS from 'aos'
import 'aos/dist/aos.css'

if (typeof window !== 'undefined' || typeof document !== 'undefined') {
  if (window.location.pathname.indexOf('admin') > 0) {
    AOS.init({
      disable: false,
    })
  } else AOS.init({ disable: false })
}

const JobsListing = ({ job, isOpen }) => {
  // state used for expanding drop downs
  const [clicked, setClicked] = useState(isOpen)

  function handleClick (event) {
    isOpen = !isOpen
    setClicked(!clicked)
  }

  return (
    <div className='job-listing' onClick={handleClick} key={isOpen}>
      <h3 className='big'><strong>{job.Job_Title}</strong>
        {!((clicked && !isOpen) || (!clicked && isOpen)) && <img alt='arrow-down' src={require('../../assets/img/arrow_jobs_down.svg')} width='25px' height='25px' />}
        {((clicked && !isOpen) || (!clicked && isOpen)) && <img alt='arrow-up' src={require('../../assets/img/arrow_jobs_up.svg')} width='25px' height='25px' />}
      </h3>
      {((clicked && !isOpen) || (!clicked && isOpen)) && <div><br /><p><strong>Description:</strong> {job.Job_Description}</p></div>}
      {((clicked && !isOpen) || (!clicked && isOpen)) && <div><br /><p><strong>Skills Needed:</strong> {job.Specific_Needs_Skills_Needed}</p></div>}
    </div>
  )
}

const JobsPageTemplate = ({ title, subtitle }) => {
  // this is the broader container

  const [allClicked, setAllClicked] = useState(false)
  const data = useStaticQuery(graphql`
    query JobQuery {
      JobOpenings: allAirtable(
        filter: {data: {Status: {ne: "Completed"}}, table: { eq: "R- Specific Recruitment" } }
      ) {
        nodes {
          data {
            Job_Title
            Job_Description
            Specific_Needs_Skills_Needed
          }
          recordId
        }
      }
    }
  `)

  function handleClick (event) {
    setAllClicked(!allClicked)
  }

  return (
    <div>
      <section id='aboutheader' className='hero is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title' data-aos='zoom-out' data-aos-duration='1000'>
                    {title}
                  </h1>
                  <h2 className='subtitle' data-aos='zoom-out' data-aos-duration='1000'>
                    {subtitle}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='intro'>
        <p>
          Thank you for your interest in joining PPE4ALL! Below are some of our current volunteer openings.
          Click on a position to learn more about its details along with some of the skills
          needed to succeed in the role. If you are interested in helping, please visit our
          <a href='https://www.linkedin.com/company/ppe4all/' className='email-link'> LinkedIn  </a> or
          contact <a href='mailto:recruitment@ppe4all.net' className='email-link'>recruitment@ppe4all.net</a>
          . Make sure to include which position(s) you are specifically interested in.
        </p>
        <button className='expand-all' onClick={handleClick}>
          Expand All
        </button>
      </section>
      <hr className='divider' />
      <section id='jobs'>
        {data.JobOpenings.nodes.map(job => (
          <JobsListing job={job.data} isOpen={allClicked} key={0} />
        ))}
      </section>
    </div>
  )
}

export default JobsPageTemplate
